<template>
  <div class="byCount" v-loading="loading">
    <el-divider>{{ $t('statisticalManagement.newActiveTrendChart') }}</el-divider>

    <div class="echart-wrap">
      <trend-line :xAxisData="xAxisData" :chartData="tableData" />
    </div>

    <el-divider>{{ $t('details') }}</el-divider>

    <div class="table-wrap">
      <el-table size="mini" height="100%" :data="tableData" border>
        <el-table-column
          show-overflow-tooltip
          prop="date"
          :label="$t('statisticalManagement.date')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="increaseNumber"
          :label="$t('statisticalManagement.newDevice')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="activeNumber"
          :label="$t('statisticalManagement.activeDevice')"
        />
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <el-button type="text" @click="download(row)">{{
              $t('download')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    /> -->
  </div>
</template>

<script>
import TrendLine from './TrendLine.vue'

export default {
  props: ['xDataType', 'modelId', 'groupId'],
  data() {
    return {
      total: 0,
      loading: false,
      tableData: [],
      xAxisData: []
    }
  },
  created() {
    this.getData(this.$utils.getStartEndTime('today'))
  },
  methods: {
    async download(row) {
      row.groupId = this.groupId
      row.modelId = this.modelId

      try {
        this.loading = true
        const res = await this.$api.statisticalManagement.exportByActive(row)

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    },
    async getData(params) {
      this.loading = true

      params.groupId = this.groupId
      params.modelId = this.modelId
      params.pageSize = 100

      try {
        const res = await this.$api.statisticalManagement.deviceStatisticByActive(params)
        if (res.result) {
          this.tableData = res.result.list.map(item => {
            if (this.xDataType === 'today' || this.xDataType === 'yesterday') {
              const target = this[this.xDataType]

              item.startTime = target + ' ' + item.date + ':00:00'
              item.endTime = target + ' ' + (Number(item.date) + 1) + ':00:00'
              item.date = item.date + ':00'
            } else {
              item.startTime = item.date + ' 00:00:00'
              item.endTime = item.date + ' 23:59:59'
            }

            return item
          })

          this.xAxisData = this.tableData
            .sort((a, b) => +new Date(a.date) - +new Date(b.date))
            .map(item => item.date)
          this.total = res.result.total
        } else {
          this.tableData = []
          this.xAxisData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    today() {
      return this.$utils.formatTime(Date.now(), 'YYYY-MM-DD')
    },
    yesterday() {
      return this.$utils.formatTime(Date.now() - 24 * 3600 * 1000, 'YYYY-MM-DD')
    }
  },
  components: {
    TrendLine
  },
  watch: {
    xDataType(val) {
      if (val !== 'custom') {
        this.getData(this.$utils.getStartEndTime(val))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.byCount {
  display: flex;
  flex-direction: column;
  flex: 1;
  .echart-wrap {
    display: flex;
    min-height: 150px;
    height: 22vh;
  }
}
</style>
