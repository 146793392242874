import { render, staticRenderFns } from "./VersionPie.vue?vue&type=template&id=58cc58ce&scoped=true"
import script from "./VersionPie.vue?vue&type=script&lang=js"
export * from "./VersionPie.vue?vue&type=script&lang=js"
import style0 from "./VersionPie.vue?vue&type=style&index=0&id=58cc58ce&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58cc58ce",
  null
  
)

export default component.exports