var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-cascader',{attrs:{"placeholder":_vm.$t('deviceModal.selectGrouping'),"options":_vm.treeData,"show-all-levels":false,"clearable":_vm.clearable,"props":{
    children: 'children',
    label: 'name',
    value: 'id',
    leaf: 'isLeaf',
    emitPath: false,
    checkStrictly: true
  },"filterable":""},on:{"change":_vm.change},model:{value:(_vm.v),callback:function ($$v) {_vm.v=$$v},expression:"v"}})
}
var staticRenderFns = []

export { render, staticRenderFns }