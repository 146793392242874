<template>
  <div class="byCount" v-loading="loading">
    <el-divider>{{ $t('customerStatistical.appInstallChart') }}</el-divider>

    <div class="echart-wrap">
      <system-pie :chartData="tableData" />
    </div>

    <el-divider>{{ $t('details') }}</el-divider>

    <div class="table-wrap">
      <el-table size="mini" :data="tableData" border height="100%">
        <el-table-column prop="statusStr" :label="$t('activeDevice.state')" align="center" />
        <el-table-column
          prop="deviceNumber"
          :label="$t('otaList.equipmentNumber')"
          align="center"
        />
        <el-table-column prop="ratio" :label="$t('statisticalManagement.ratio')" align="center" />
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" v-if="!row.status" @click="supplyAgain(row)">{{
                $t('customerStatistical.reissue')
              }}</el-button>
              <el-button type="text" @click="download(row)">{{
                $t('customerStatistical.export')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    /> -->
  </div>
</template>

<script>
import SystemPie from './SystemPie.vue'

export default {
  props: ['appId', 'publishId'],
  data() {
    return {
      total: 0,
      loading: false,
      // params: {
      //   pageSize: 50,
      //   currentPage: 1
      // },
      tableData: [],
      statusList: {
        0: this.$t('customerStatistical.InstallFailed'),
        3: this.$t('customerStatistical.InstallSuccess')
      }
    }
  },
  methods: {
    async supplyAgain(row) {
      try {
        await this.$confirm(this.$t('customerStatistical.isReissue'), this.$t('tips'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
        this.loading = true

        await this.$api.statisticalManagement.reissueApp2Device({
          appId: this.appId,
          publishId: this.publishId
        })
        this.$message.success(this.$t('customerStatistical.reissueSuccess'))
        this.getData(false)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async download(row) {
      try {
        this.loading = true
        const res = await this.$api.statisticalManagement.exportDeviceListByAppId({
          appId: this.appId,
          publishId: this.publishId,
          status: row.status
        })

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    },
    async getData(params) {
      params.appId = this.appId

      try {
        this.loading = true

        const res = await this.$api.statisticalManagement.listDeviceNumByAppId(params)

        if (res && res.result) {
          this.tableData = res.result.map((item, index) => {
            item.statusStr =
              this.statusList[+item.status] || this.$t('customerStatistical.InstallFailed')
            return item
          })
        } else {
          this.tableData = []
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    SystemPie
  }
}
</script>

<style lang="scss" scoped>
.byCount {
  display: flex;
  flex-direction: column;
  flex: 1;
  .echart-wrap {
    display: flex;
    min-height: 150px;
    height: 22vh;
  }
}
</style>
