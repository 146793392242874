import utils from '@/lib/utils'

const end = utils.formatTime(new Date(), 'YYYY-MM-DD') + ' 23:59:59'

let start = new Date()
// const hours = utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * 1))

const week =
  utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * 6), 'YYYY-MM-DD') + ' 00:00:00'

const month =
  utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * 30), 'YYYY-MM-DD') + ' 00:00:00'

const threeMonths =
  utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * 90), 'YYYY-MM-DD') + ' 00:00:00'

// 结束日期不可选择当日之前
const pickerOptions = {
  startDate: null,
  disabledDate: time => {
    if (pickerOptions.startDate) {
      return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000
    }
  },
  onPick: dateRange => {
    pickerOptions.startDate = dateRange.minDate
    if (dateRange.maxDate) {
      pickerOptions.startDate = null
    }
  }
}

export default {
  shortcuts: [
    // {
    //   text: '最近24小时',
    //   onClick(picker) {
    //     picker.$emit('pick', [hours, end])
    //   }
    // },
    {
      text: '最近一周',
      onClick(picker) {
        picker.$emit('pick', [week, end])
      }
    },
    {
      text: '最近一个月',
      onClick(picker) {
        picker.$emit('pick', [month, end])
      }
    },
    {
      text: '最近三个月',
      onClick(picker) {
        picker.$emit('pick', [threeMonths, end])
      }
    }
  ]
}

const yesterday = utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * 1), 'YYYY-MM-DD')

//
let day = start.getDay() == 0 ? 6 : start.getDay() - 1

// 当前周（周一）开始的日期
const startWeek =
  utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * day), 'YYYY-MM-DD') + ' 00:00:00'
// 当前周（周日）结束的日期
const endWeek =
  utils.formatTime(new Date(start.getTime() + 3600 * 1000 * 24 * (6 - day)), 'YYYY-MM-DD') +
  ' 23:23:59'

// 上周（周一）开始的日期
const startPreWeek =
  utils.formatTime(new Date(start.getTime() - 3600 * 1000 * 24 * (7 + day)), 'YYYY-MM-DD') +
  ' 00:00:00'
// 上周（周日）结束的日期
const endPreWeek =
  utils.formatTime(new Date(start.getTime() + 3600 * 1000 * 24 * (-1 - day)), 'YYYY-MM-DD') +
  ' 23:23:59'

// 当前月的开始日期1号
const startMonthDay = utils.formatTime(new Date(new Date().setDate(1)), 'YYYY-MM-DD') + ' 00:00:00'

// 当前月的结束日期最后一个天
const endMonthDay =
  utils.formatTime(new Date(start.getFullYear(), start.getMonth() + 1, 0), 'YYYY-MM-DD') +
  ' 23:23:59'

// 上月的开始日期1号
const startPreMonthDay =
  utils.formatTime(new Date(start.getFullYear(), start.getMonth() - 1, 1), 'YYYY-MM-DD') +
  ' 00:00:00'

// 上月的结束日期最后一个天
const endPreMonthDay =
  utils.formatTime(new Date(start.getFullYear(), start.getMonth(), 0), 'YYYY-MM-DD') + ' 23:23:59'

const shortcuts = [
  {
    text: '上月',
    onClick(picker) {
      picker.$emit('pick', [startPreMonthDay, endPreMonthDay])
    }
  },

  {
    text: '上周',
    onClick(picker) {
      picker.$emit('pick', [startPreWeek, endPreWeek])
    }
  },
  {
    text: '本周',
    onClick(picker) {
      picker.$emit('pick', [startWeek, endWeek])
    }
  },
  {
    text: '昨日',
    onClick(picker) {
      picker.$emit('pick', [yesterday + ' 00:00:00', yesterday + ' 23:23:59'])
    }
  },
  {
    text: '本月',
    onClick(picker) {
      picker.$emit('pick', [startMonthDay, endMonthDay])
      // picker.$emit('pick', ['2023-08-01 00:00:00', '2023-08-01 23:59:59'])
    }
  }
]
// 时间快捷选项
const dateShortcuts = {
  shortcuts
}

// 日期选择器的禁用
// dateShortcutsDisableDate365Option、dateBlur 共同限制日期选择时只能选择跨一年范围的数据
let startTime = ''

const dateShortcutsDisableDate365Option = {
  shortcuts,
  //时间范围选择控制
  onPick: ({ maxDate, minDate }) => {
    if (minDate) {
      startTime = minDate
    }
    if (maxDate) {
      startTime = ''
    }
  },
  disabledDate: time => {
    if (startTime) {
      return (
        time.getTime() < new Date(startTime).getTime() - 3600 * 1000 * 24 * 364 ||
        time.getTime() > new Date(startTime).getTime() + 3600 * 1000 * 24 * 364 ||
        time.getTime() > new Date(end.replaceAll('-', '/')).getTime()
      )
    } else {
      return time.getTime() > new Date(end.replaceAll('-', '/')).getTime()
    }
  }
}

// 当 DateTimePicker 失去焦点时触发 置空startTime
const dateBlur = () => {
  startTime = ''
}

//startTime2、 dateShortcutsDisableDate90Option、 dateBlur2 共同限制日期选择时只能选择跨90天范围的数据
let startTime2 = ''

const dateShortcutsDisableDate90Option = {
  shortcuts,
  //时间范围选择控制
  onPick: ({ maxDate, minDate }) => {
    if (minDate) {
      startTime2 = minDate
    }
    if (maxDate) {
      startTime2 = ''
    }
  },
  disabledDate: time => {
    if (startTime2) {
      return (
        time.getTime() < new Date(startTime2).getTime() - 3600 * 1000 * 24 * 90 ||
        time.getTime() > new Date(startTime2).getTime() + 3600 * 1000 * 24 * 90 ||
        time.getTime() > new Date(end.replaceAll('-', '/')).getTime()
      )
    } else {
      return time.getTime() > new Date(end.replaceAll('-', '/')).getTime()
    }
  }
}

// 当 DateTimePicker 失去焦点时触发 置空startTime2
const dateBlur2 = () => {
  startTime2 = ''
}

export {
  week,
  month,
  threeMonths,
  end,
  pickerOptions,
  dateShortcuts,
  dateBlur,
  dateShortcutsDisableDate365Option,
  dateBlur2,
  dateShortcutsDisableDate90Option
}
