<template>
  <page>
    <div slot="headerLeft">
      <el-select clearable v-model="params.spId" :placeholder="$t('deviceList.customer')">
        <el-option
          v-for="item in serviceList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-button type="primary" plain @click="getData">{{ $t('query') }}</el-button>
    </div>

    <component ref="chart" :spId="params.spId" :is="currentType" />
  </page>
</template>

<script>
import ByOs from './component/ByOs.vue'

export default {
  data() {
    return {
      currentType: 'ByOs',
      params: {
        spId: ''
      },

      serviceList: []
    }
  },
  created() {
    this.getSelectData()
  },
  components: {
    ByOs
  },
  methods: {
    async getSelectData() {
      const res = await this.$api.systemManagement.spList({ pageSize: -1 })
      this.serviceList = ((res.result && res.result.list) || []).map(item => {
        return {
          label: item.name,
          value: item.spId
        }
      })
    },
    async getData() {
      this.$refs.chart.getData({
        spId: this.params.spId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.timeFrame,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
