<template>
  <div class="byCount" v-loading="loading">
    <el-divider>{{$t('statisticalManagement.appInstallChart')}}</el-divider>

    <div class="echart-wrap">
      <trend-line :xAxisData="xAxisData" :chartData="chartData" />
    </div>

    <el-divider>{{$t('details')}}</el-divider>

    <div class="table-wrap">
      <el-table size="mini" :data="tableData" border height="100%">
        <el-table-column show-overflow-tooltip type="index" :label="$t('statisticalManagement.ranking')" width="80px"/>
        <el-table-column show-overflow-tooltip prop="appName" :label="$t('appList.appName')" />
        <el-table-column show-overflow-tooltip prop="spName" :label="$t('deviceList.customer')" />
        <el-table-column show-overflow-tooltip prop="deviceNumber" :label="$t('statisticalManagement.installNumber')" />
        <el-table-column show-overflow-tooltip prop="ratio" :label="$t('statisticalManagement.ratio')" />
      </el-table>
    </div>
    <!-- <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    /> -->
  </div>
</template>

<script>
import TrendLine from './TrendLine.vue'

export default {
  props: ['xDataType', 'customTime', 'spId', 'packageName'],
  data() {
    return {
      total: 0,
      loading: false,
      // params: {
      //   pageSize: 50,
      //   currentPage: 1
      // },
      tableData: [],
      chartData: [],
      xAxisData: []
    }
  },
  created() {
    this.getData(this.$utils.getStartEndTime('today'))
  },
  methods: {
    download() {},
    async getData(params) {
      try {
        this.loading = true

        if (this.packageName) {
          params.packageName = this.packageName
        }

        params.spId = this.spId

        params.pageSize = 100

        const res = await this.$api.statisticalManagement.installStatisticByApp(params)

        if (res && res.result) {
          this.tableData = res.result.deviceAppStatisticResVoList
          this.chartData = res.result.appInstallStatisticResVoList.map(item => item.deviceNumber)
          this.xAxisData = res.result.appInstallStatisticResVoList
            .sort((a, b) => +new Date(a.date) - +new Date(b.date))
            .map(item => {
              if (this.xDataType === 'today' || this.xDataType === 'yesterday') {
                return item.date + ':00'
              } else {
                return item.date
              }
            })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    TrendLine
  },
  watch: {
    xDataType(val) {
      if (val !== 'custom') {
        this.getData(this.$utils.getStartEndTime(val))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.byCount {
  display: flex;
  flex-direction: column;
  flex: 1;
  .echart-wrap {
    display: flex;
    min-height: 150px;
    height: 22vh;
  }
}
</style>
