<template>
  <page>
    <component :is="currentType" />
  </page>
</template>

<script>
import ByVersion from './component/ByVersion.vue'

export default {
  data() {
    return {
      currentType: 'ByVersion',
      params: {
        model: '',
        groupId: ''
      },

      serviceList: [],
      groupList: [],
      appList: []
    }
  },
  created() {},
  components: {
    ByVersion
  },
  methods: {
    async getData() {}
  }
}
</script>

<style lang="scss" scoped>
.timeFrame,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
