<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },

  data() {
    return {
      opt: {
        color: ['#42a5f3', '#fcca2c', '#ccccdd', '#6d7c87', '#48c46b', '#fe6f44'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}'+this.$t('adminHomePage.tower') +' ({d}%)',
          confine: true
        },
        legend: {
          bottom: 0,
          icon: 'circle',
          data: ['7.1.2', '5.1.1'],
          type: 'scroll'
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            data: []
          }
        ]
      }
    }
  },

  watch: {
    chartData(val) {
      if (val) {
        this.opt.series[0].data = val.map(item => ({
          name: item.systemVersionStr,
          value: item.deviceNumber
        }))

        this.opt.legend.data = val.map(_ => _.systemVersionStr)
      } else {
        this.opt.legend.data = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  flex: 1;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
