<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'

export default {
  props: {
    xAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    chartData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        color: ['#5bb3e0', '#ffde76'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: [this.$t('newadd'), this.$t('customerStatistical.active')]
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title:this.$t('customerStatistical.savePicture')
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [
          {
            name: this.$t('newadd'),
            type: 'line',
            smooth: true,
            stack: this.$t('spaceDetails.total'),
            areaStyle: {},
            data: []
          },
          {
            name: this.$t('customerStatistical.active'),
            type: 'line',
            smooth: true,
            stack: this.$t('spaceDetails.total'),
            areaStyle: {},
            data: []
          }
        ]
      }
    }
  },
  created() {
    this.opt.xAxis.data = this.xAxisData
    this.opt.series[0].data = this.chartData.map(item => item.increaseNumber)
    this.opt.series[1].data = this.chartData.map(item => item.activeNumber)
  },

  watch: {
    chartData(val) {
      this.opt.series[0].data = val.map(item => item.increaseNumber)
      this.opt.series[1].data = val.map(item => item.activeNumber)
    },
    xAxisData(val) {
      this.opt.xAxis.data = val
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  flex: 1;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
