<template>
  <page>
    <div slot="headerLeft">
      <el-form inline>
        <el-form-item :label="$t('electronicScalesReminder.sn')">
          <el-input
            :placeholder="$t('electronicScalesReminder.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('electronicScalesReminder.group')">
          <GroupCascader :isDisabledAll="false" clearable :value.sync="params.groupId" />
        </el-form-item>
        <el-form-item :label="$t('electronicScalesReminder.store')">
          <el-input
            :placeholder="$t('electronicScalesReminder.store')"
            v-model.trim="params.store"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('electronicScalesReminder.remindTime')">
          <el-date-picker
            v-model="remindTimeRange"
            type="datetimerange"
            range-separator="-"
            :default-time="['00:00:00', '23:59:59']"
            :start-placeholder="$t('electronicScalesReminder.startTime')"
            :end-placeholder="$t('electronicScalesReminder.endTime')"
            :clearable="false"
            :editable="false"
            :picker-options="dateShortcutsDisableDate90Option"
            @blur="dateBlur2"
          />
        </el-form-item>
        <el-form-item :label="$t('electronicScalesReminder.executeTime')">
          <el-date-picker
            v-model="executeTimeRange"
            type="datetimerange"
            range-separator="-"
            :default-time="['00:00:00', '23:59:59']"
            :start-placeholder="$t('electronicScalesReminder.startTime')"
            :end-placeholder="$t('electronicScalesReminder.endTime')"
          />
        </el-form-item>
        <el-button type="primary" plain @click="replaceData()">{{ $t('query') }}</el-button>
        <el-button size="medium" plain @click="resetHandler">
          {{ $t('reset') }}
        </el-button>
        <el-button type="info" plain @click="exportData()">
          {{ $t('export') }}
        </el-button>
      </el-form>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column prop="time" :label="$t('electronicScalesReminder.remindTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.remindTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="time" :label="$t('electronicScalesReminder.executeTime')">
          <template slot-scope="{ row }">
            {{
              row.executeTime
                ? $utils.formatTime(row.executeTime)
                : $t('electronicScalesReminder.unexecuted')
            }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sn"
          :label="$t('electronicScalesReminder.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('electronicScalesReminder.deviceModel')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          :label="$t('electronicScalesReminder.group')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="store"
          :label="$t('electronicScalesReminder.store')"
        />
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'
import { dateBlur2, dateShortcutsDisableDate90Option } from '@/assets/js/dateShortcutSelection'

export default {
  mixins: [watchParamsGetData],
  components: { GroupCascader },
  data() {
    return {
      loading: false,
      remindTimeRange: [
        this.$utils.formatTime(new Date(), 'YYYY-MM-DD') + ' 00:00:00',
        this.$utils.formatTime(new Date(), 'YYYY-MM-DD') + ' 23:59:59'
      ],
      executeTimeRange: [],
      tableData: [],
      dateShortcutsDisableDate90Option
    }
  },
  created() {},
  methods: {
    dateBlur2,
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      let data = { ...this.params }
      if (this.remindTimeRange) {
        data.remindTimeStart = this.$utils.formatTime(this.remindTimeRange[0])
        data.remindTimeEnd = this.$utils.formatTime(this.remindTimeRange[1])
      }
      if (this.executeTimeRange) {
        data.executeTimeStart = this.$utils.formatTime(this.executeTimeRange[0])
        data.executeTimeEnd = this.$utils.formatTime(this.executeTimeRange[1])
      }
      if (data?.groupId == 200) {
        data.groupId = undefined
      }
      try {
        const res = await this.$api.systemManagement.deviceRemindList(data)
        if (res.result) {
          this.tableData = res.result.list || []
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    resetHandler() {
      this.remindTimeRange = [
        this.$utils.formatTime(new Date(), 'YYYY-MM-DD') + ' 00:00:00',
        this.$utils.formatTime(new Date(), 'YYYY-MM-DD') + ' 23:59:59'
      ]
      this.executeTimeRange = []
      this.params.sn = undefined
      this.params.groupId = undefined
      this.params.store = undefined
      this.params.remindTimeStart = undefined
      this.params.remindTimeEnd = undefined
      this.params.executeTimeStart = undefined
      this.params.executeTimeEnd = undefined
      this.getData()
    },
    async exportData() {
      const params = this.params
      if (this.remindTimeRange) {
        params.remindTimeStart = this.$utils.formatTime(this.remindTimeRange[0])
        params.remindTimeEnd = this.$utils.formatTime(this.remindTimeRange[1])
      }
      if (this.executeTimeRange) {
        params.executeTimeStart = this.$utils.formatTime(this.executeTimeRange[0])
        params.executeTimeEnd = this.$utils.formatTime(this.executeTimeRange[1])
      }
      try {
        console.log(params)
        const res = await this.$api.systemManagement.deviceRemindExport(params)
        this.$utils.downFile(res)
        this.$message.success(this.$t('succeeded'))
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss"></style>
