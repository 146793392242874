<template>
  <page>
    <div slot="headerLeft">
      <el-select
        clearable
        v-model="params.modelId"
        :placeholder="$t('statisticalManagement.deviceType')"
      >
        <el-option
          v-for="item in modelList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <!-- <el-select clearable v-model="params.groupId" :placeholder="$t('deviceList.deviceGroup')">
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select> -->
      <GroupCascader :value.sync="params.groupId" />
      <el-button type="primary" plain @click="getData">{{ $t('query') }}</el-button>
    </div>

    <div class="common-tab">
      <span
        @click="currentType = item.type"
        :class="{ active: currentType === item.type }"
        v-for="item in spanItems"
        :key="item.type"
        class="spanItem"
        >{{ item.name }}</span
      >
    </div>

    <component
      ref="chart"
      :modelId="params.modelId"
      :groupId="params.groupId"
      :currentType="currentType"
      :is="currentType"
    />
  </page>
</template>

<script>
import ByTemperature from './component/ByTemperature.vue'
import ByMemory from './component/ByMemory.vue'
import ByStorage from './component/ByStorage.vue'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'

export default {
  data() {
    return {
      currentType: 'ByTemperature',
      params: {
        modelId: '',
        groupId: ''
      },
      spanItems: [
        {
          type: 'ByTemperature',
          name: this.$t('statisticalManagement.byCPUTemperature')
        },
        {
          type: 'ByMemory',
          name: this.$t('statisticalManagement.byMemoryPercentage')
        },
        {
          type: 'ByStorage',
          name: this.$t('statisticalManagement.byStoragePercentage')
        }
      ],
      modelList: [],
      groupList: []
    }
  },
  created() {
    this.getSelectData()
  },
  components: {
    ByTemperature,
    ByMemory,
    ByStorage,
    GroupCascader
  },
  methods: {
    async getSelectData() {
      let result = await Promise.all([
        // this.$api.deviceManagement.groupList({ pageSize: -1 }),
        this.$api.deviceManagement.modelList({ pageSize: -1 })
      ])

      // this.groupList = result[0].result.list.map(item => {
      //   return {
      //     label: item.name,
      //     value: item.id
      //   }
      // })
      if (result) {
        this.modelList = result[0].result.list.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } else {
        this.modelList = []
      }
    },
    async getData() {
      this.$refs.chart.getData({
        modelId: this.params.modelId,
        groupId: this.params.groupId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.timeFrame,
.picker-wrap {
  display: flex;
  align-items: center;
}
</style>
