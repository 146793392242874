<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
  props: ['chartData'],
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        color: ['#42a5f3', '#fcca2c', '#ccccdd', '#6d7c87', '#48c46b', '#fe6f44'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}台 ({d}%)',
          confine: true
        },
        legend: {
          bottom: 0,
          icon: 'circle',
          data: [],
          type: 'scroll'
        },
        series: {
          type: 'pie',
          radius: '35%',
          center: ['50%', '42%'],
          data: []
        }
      }
    }
  },
  created() {
    this.renderData(this.chartData)
  },
  methods: {
    renderData(val) {
      if (val) {
        const legend = []
        const targetData = []

        this.chartData.forEach(item => {
          legend.push(item.firmwareVersion)
          targetData.push({
            name: item.firmwareVersion,
            value: item.deviceNumber
          })
        })

        this.opt.legend.data = legend
        this.opt.series.data = targetData
      } else {
        this.opt.legend.data = []
        this.opt.series.data = []
      }
    }
  },
  watch: {
    chartData(val) {
      this.renderData(val)
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  flex: 1;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
