<template>
  <div class="byVersion" v-loading="loading">
    <el-divider>{{ $t('statisticalManagement.versionDistributionMap') }}</el-divider>

    <div class="echart-wrap">
      <version-pie :chartData="tableData" />
    </div>

    <el-divider>{{ $t('details') }}</el-divider>

    <div class="table-wrap">
      <el-table size="mini" :data="tableData" border height="100%">
        <el-table-column
          min-width="200"
          show-overflow-tooltip
          prop="firmwareVersion"
          :label="$t('statisticalManagement.version')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="deviceNumber"
          :label="$t('adminHomePage.deviceTotal')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="ratio"
          :label="$t('statisticalManagement.ratio')"
        />
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow" v-if="row.firmwareVersion != $t('deviceRoom.noVersion2')">
              <el-button type="text" @click="detail(row)">{{
                $t('details')
              }}</el-button>
              <el-button type="text" @click="download(row)">{{
                $t('download')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    /> -->

    <el-dialog
      v-elDragDialog
      append-to-body
      width="800px"
      :title="$t('details')"
      :close-on-click-modal="false"
      :visible.sync="detailVisible"
    >
      <div style="display:flex;justify-content: space-between;margin-bottom:10px">
        <span>{{ $t('statisticalManagement.version') }}: {{ currentVersion }}</span>
        <span>{{ $t('publishApp.common') }} {{ deviceTotal }} {{ $t('adminHomePage.tower') }}</span>
      </div>
      <el-table :height="400" :data="tableDataDetail" border stripe>
        <el-table-column prop="modelName" :label="$t('deviceList.deviceType')" align="center" />
        <el-table-column
          prop="deviceNumber"
          :label="$t('adminHomePage.deviceTotal')"
          align="center"
        />
        <el-table-column prop="ratio" :label="$t('statisticalManagement.ratio')" align="center" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import VersionPie from './VersionPie.vue'

export default {
  props: ['xDataType', 'customTime', 'modelId', 'groupId'],
  data() {
    return {
      total: 0,
      deviceTotal: 0,
      detailVisible: false,
      loading: false,
      currentVersion: '',
      params: {
        pageSize: 50,
        currentPage: 1
      },
      tableData: [],
      tableDataDetail: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async detail(row) {
      this.currentVersion = row.firmwareVersion

      try {
        this.loading = true
        const res = await this.$api.statisticalManagement.deviceStatisticByFirmwareVersion({
          firmwareVersion: row.firmwareVersion
        })
        if (res.result && res.result.deviceModelStatisticResVoList) {
          this.tableDataDetail = res.result.deviceModelStatisticResVoList
          this.deviceTotal = res.result.deviceTotal
        }
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }

      this.detailVisible = true
    },
    async download(row) {
      row.groupId = this.groupId
      row.modelId = this.modelId
      const loading = this.$loading()
      try {
        const res = await this.$api.statisticalManagement.exportBySystemVersion(row)

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getData() {
      this.loading = true

      try {
        const res = await this.$api.statisticalManagement.deviceStatisticByFirmwareGroup({
          modelId: this.modelId,
          groupId: this.groupId,
          pageSize: 100
        })
        if (res.result) {
          this.tableData = (res.result || []).map(item => {
            if (!item.firmwareVersion) {
              item.firmwareVersion = this.$t('deviceRoom.noVersion')
            }
            return item
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  components: {
    VersionPie
  }
}
</script>

<style lang="scss" scoped>
.byVersion {
  display: flex;
  flex-direction: column;
  flex: 1;
  .echart-wrap {
    display: flex;
    min-height: 150px;
    height: 24vh;
  }
}
</style>
