<template>
  <div class="wrap">
    <div class="wrap-ech">
      <v-chart :options="opt" autoresize />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'

export default {
  props: {
    currentType: {
      type: String,
      default: 'ByTemperature'
    },
    chartData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    VChart: VChart
  },
  data() {
    return {
      opt: {
        color: ['#074d29'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: []
        },
        yAxis: {
          name: this.$t('adminHomePage.unit') + '：' + this.$t('adminHomePage.tower'),
          type: 'value',
          minInterval: 1
        },
        series: {
          name: this.$t('statisticalManagement.deviceDistribution'),
          type: 'bar',
          barWidth: 20,
          barGap: '30%',
          data: []
        }
      }
    }
  },
  watch: {
    chartData(val) {
      if (val.length) {
        this.opt.xAxis.data = this.chartData.map(item => item.xData)
        this.opt.series.data = this.chartData.map(item => item.deviceNumber)
      } else {
        this.opt.xAxis.data = []
        this.opt.series.data = []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  flex: 1;
  .wrap-ech {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    .echarts {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
