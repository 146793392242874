<template>
  <page>
    <div slot="headerLeft">
      <el-form inline>
        <el-form-item :label="$t('deviceSelfTest.sn')">
          <el-input
            :placeholder="$t('deviceSelfTest.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('deviceSelfTest.selfTestResult')">
          <el-select clearable v-model="params.selfTestResult" :placeholder="$t('placeholder')">
            <el-option :label="$t('deviceList.normal')" value="0" />
            <el-option :label="$t('deviceList.abnormal')" value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceSelfTest.finalSelfTestTime')">
          <el-date-picker
            v-model="finalSelfTestTimeRange"
            type="daterange"
            range-separator="-"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :start-placeholder="$t('deviceSelfTest.startTime')"
            :end-placeholder="$t('deviceSelfTest.endTime')"
          />
        </el-form-item>
        <el-button type="primary" plain @click="replaceData()">{{ $t('query') }}</el-button>
        <el-button size="medium" plain @click="resetHandler">
          {{ $t('reset') }}
        </el-button>
        <el-button type="info" plain @click="exportData()">
          {{ $t('export') }}
        </el-button>
      </el-form>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column show-overflow-tooltip prop="sn" :label="$t('deviceSelfTest.sn')" />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceSelfTest.model')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          :label="$t('deviceSelfTest.group')"
        />
        <el-table-column show-overflow-tooltip prop="store" :label="$t('deviceSelfTest.store')" />
        <el-table-column :label="$t('deviceSelfTest.finalSelfTestTime')">
          <template slot-scope="{ row }">
            {{ row.selfTestTime ? $utils.formatTime(row.selfTestTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('deviceSelfTest.selfTestResult')">
          <template slot-scope="{ row }">
            <span v-if="row.selfTestResult == 0">{{ $t('deviceList.normal') }}</span>
            <span v-if="row.selfTestResult == 1"
              ><font color="red">{{ $t('deviceList.abnormal') }}</font></span
            >
          </template>
        </el-table-column>
        <el-table-column :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" @click="getDetail(row)">{{
                $t('deviceSelfTest.selfTestDetail')
              }}</el-button>
              <el-button type="text" @click="getRecord(row)">{{
                $t('deviceSelfTest.operateRecord')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        :title="$t('deviceSelfTest.operationRecords')"
        v-if="recordVisable"
        :visible.sync="recordVisable"
        :before-close="resetParam"
      >
        <div>
          <el-table :data="selfTestRecord">
            <el-table-column
              prop="operateTime"
              :label="$t('deviceSelfTest.operateTime')"
              width="200"
            >
              <template slot-scope="{ row }">
                {{ row.operateTime ? $utils.formatTime(row.operateTime) : '-' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="userName"
              :label="$t('deviceSelfTest.operateUserName')"
              width="200"
            />
            <el-table-column
              :prop="$i18n.locale == 'en' ? 'contentEn' : 'content'"
              :label="$t('deviceSelfTest.operateContent')"
            />
          </el-table>
          <pagination
            :total="recordTotal"
            :pageSize.sync="recordPageSize"
            :page.sync="recordCurrentPage"
            @change="pageRecord"
          />
        </div>
      </el-dialog>
      <el-dialog :title="$t('details')" :visible.sync="detailVisable">
        <div>
          <el-table :data="detail">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="table-expand">
                  <div
                    v-for="(item, index) in props.row.detailList"
                    style="display:flex;width: 100%;"
                    :key="index"
                  >
                    <div v-if="item.selfTestKey == 'connect'" style="width: 40%;">
                      <el-form-item :label="$t('deviceSelfTest.connectTest')">
                        <span v-if="item.selfTestResult == 0">{{ $t('deviceList.normal') }}</span>
                        <span v-if="item.selfTestResult == 1"
                          ><font color="red">{{ $t('deviceList.abnormal') }}</font></span
                        >
                      </el-form-item>
                    </div>
                    <div v-if="item.selfTestKey == 'print'" style="width: 40%;">
                      <el-form-item :label="$t('deviceSelfTest.printTest')">
                        <span v-if="item.selfTestResult == 0">{{ $t('deviceList.normal') }}</span>
                        <span v-if="item.selfTestResult == 1"
                          ><font color="red">{{ $t('deviceList.abnormal') }}</font></span
                        >
                      </el-form-item>
                    </div>
                    <div v-if="item.selfTestKey == 'weigh'" style="width: 40%;">
                      <el-form-item :label="$t('deviceSelfTest.weighTestDeviation')">
                        <span>{{ item.selfTestValue }}g</span>
                      </el-form-item>
                    </div>
                    <div>
                      <el-form-item
                        :label="$t('deviceSelfTest.selfTestTime')"
                        style="width: 100%;"
                        label-width="80px"
                      >
                        <span>{{
                          item.selfTestTime ? $utils.formatTime(item.selfTestTime) : '-'
                        }}</span>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column prop="type" :label="$t('deviceSelfTest.deviceType')">
              <template slot-scope="{ row }">
                <span v-if="row.type == 'printer'">{{ $t('deviceList.printer') }}</span>
                <span v-if="row.type == 'electronicScales'">{{
                  $t('deviceList.electronicScales')
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('deviceSelfTest.deviceName')" />
            <el-table-column :label="$t('deviceSelfTest.userSelfTestResult')">
              <template slot-scope="{ row }">
                <span v-if="row.selfTestResult == 0">{{ $t('deviceList.normal') }} </span>
                <span v-if="row.selfTestResult == 1"
                  ><font color="red">{{ $t('deviceList.abnormal') }} </font></span
                >
                <el-link type="primary" @click="setToNormal(row)" v-if="row.selfTestResult == 1">
                  {{ $t('deviceSelfTest.setToNormal') }}
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      finalSelfTestTimeRange: [],
      tableData: [],
      recordPageSize: 20,
      recordCurrentPage: 1,
      recordTotal: 0,
      recordVisable: false,
      selfTestRecord: [],
      detailVisable: false,
      detail: [],
      recordSn: ''
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      const data = this.params
      data.spId = sessionStorage.getItem('spId')
      if (this.finalSelfTestTimeRange.length > 0) {
        data.startTime = this.finalSelfTestTimeRange[0] + ' 00:00:00'
        data.endTime = this.finalSelfTestTimeRange[1] + ' 23:59:59'
      }
      try {
        const res = await this.$api.systemManagement.deviceSelfTestList(data)
        if (res.result) {
          this.tableData = res.result.list || []
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    resetHandler() {
      this.finalSelfTestTimeRange = []
      this.params.sn = undefined
      this.params.selfTestResult = undefined
      this.params.startTime = undefined
      this.params.endTime = undefined
      this.getData()
    },
    async exportData() {
      const data = this.params
      data.spId = sessionStorage.getItem('spId')
      if (this.finalSelfTestTimeRange.length > 0) {
        data.startTime = this.finalSelfTestTimeRange[0] + ' 00:00:00'
        data.endTime = this.finalSelfTestTimeRange[1] + ' 23:59:59'
      }
      try {
        const res = await this.$api.systemManagement.deviceSelfTestExport(data)
        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      }
    },
    async getRecord(row) {
      let param = {}
      param.pageSize = this.recordPageSize
      param.currentPage = this.recordCurrentPage
      param.sn = row.sn
      this.recordSn = row.sn
      try {
        const res = await this.$api.systemManagement.deviceSelfTestRecordList(param)
        this.selfTestRecord = res.result.list
        this.recordVisable = true
        this.recordTotal = res.result.total
      } catch (e) {
        console.log(e)
      }
    },
    async getDetail(row) {
      try {
        this.recordSn = row.sn
        const res = await this.$api.systemManagement.deviceSelfTestDetailList(row.sn)
        if (res.result) {
          this.detail = res.result
          this.detailVisable = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    async setToNormal(row) {
      try {
        let data = {}
        data.peripheralDeviceId = row.peripheralDeviceId
        data.sn = this.recordSn
        const res = await this.$api.systemManagement.deviceSelfTestSetToNormal(data)
        if (res) {
          this.detailVisable = false
          this.$message({
            message: '设置成功',
            type: 'success'
          })
          this.getData()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async resetParam() {
      this.recordPageSize = 20
      this.recordCurrentPage = 1
      this.recordSn = undefined
      this.recordVisable = false
    },
    async pageRecord() {
      let param = {}
      param.sn = this.recordSn
      this.getRecord(param)
    }
  }
}
</script>

<style lang="scss">
.table-expand {
  font-size: 0;
}
.table-expand label {
  width: 110px;
  color: #99a9bf;
}
.table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
